import React, { useState } from 'react';
import Sidebar from './Sidebar';
import tw, { styled } from 'twin.macro';

const Layout = ({ children }) => {
  const [isOpen, setIsOpen] = useState(true);
  return (
    <>
      <Sidebar isOpen={isOpen} setIsOpen={setIsOpen} />
      <StyledMain isOpen={isOpen}>
        {/* <Navbar isOpen={isOpen} setIsOpen={setIsOpen} /> */}
        <div tw='container mt-5 px-2 md:px-5'>{children}</div>
      </StyledMain>
    </>
  );
};

export default Layout;

const StyledMain = styled.main`
  ${tw`relative top-0 min-h-screen`}
  left: 4rem;
  transition: all 0.5s ease;
  width: ${(p) => (p.isOpen ? 'calc(100% - 16rem)' : 'calc(100% - 4rem)')};
  ${(p) => (p.isOpen ? tw`left[16rem]` : tw`left[4rem]`)}
`;
