export const childStatuses = [
  { id: "", name: "None" },
  { id: "Regular", name: "Regular" },
  { id: "New", name: "New" },
  { id: "Looking for tutor", name: "Looking for tutor" },
  { id: "Green", name: "Green" },
  { id: "Pending 1st lesson", name: "Pending 1st lesson" },
  { id: "Group courses only", name: "Group courses only" },
  { id: "Call much later", name: "Call much later" },
  { id: "Call later", name: "Call later" },
  { id: "Not active", name: "Not active" },
  { id: "Old new", name: "Old new" },
  { id: "Old green", name: "Old green" },
  { id: "Gave up", name: "Gave up" },
  { id: "Get to restart", name: "Get to restart" },
  { id: "Old new", name: "Old new" },
  { id: "Bus event", name: "Bus event" },
];