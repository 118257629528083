import React, { useState } from 'react';
import tw, { styled } from 'twin.macro';
import Avatar from 'react-avatar';
import { Link } from 'react-router-dom';
import { RiMoneyDollarCircleFill } from 'react-icons/ri';
import Modal from 'components/common/Modal';
import useAlert from 'hooks/useAlert';
import { removeAssignment } from 'api';

const times = [
  '30 minutes',
  '45 minutes',
  '1 Hour',
  '1 Hour 15 minutes',
  '1 Hour 30 minutes',
  '1 Hour  45 minutes',
  '2 Hours',
];

const AssignedTutorCard = ({
  fetchStudent,
  id,
  tutorId,
  tutorName,
  prices,
  childName,
  commissions,
  onlinePrices,
  onlineCommissions,
  type,
}) => {
  const allPrices = prices?.split(',');
  const allOnlinePrices = onlinePrices?.split(',');
  const allCommissions = commissions?.split(',');
  const allOnlineCommissions = onlineCommissions?.split(',');

  const [showModal, setShowModal] = useState(false);
  const { ConfirmDialog } = useAlert();

  const handleRemove = () => {
    ConfirmDialog("You won't be able to revert this!", 'Yes, Delete it!', async () => {
      await removeAssignment(id);
      fetchStudent();
    });
  };

  return (
    <div>
      <StyledCard>
        <div className='badge'>
          <p className='type'>{type === 'Both' ? 'Online & In-Person' : type}</p>
        </div>
        <Avatar name={tutorName} round={true} size='80' />
        <div className='body'>
          <h4>
            <Link to={`/tutor/${tutorId}`}>{tutorName}</Link>
          </h4>
          <div className='info'>
            <p>
              <RiMoneyDollarCircleFill />
              <span>
                Assigned to: <b>{childName}</b>
              </span>
            </p>
            <button className='priceButton' onClick={() => setShowModal(true)}>
              See Prices & Commissions
            </button>
            <Modal
              heading={`Prices & Commissions for - ${tutorName} (${childName})`}
              isOpen={showModal}
              onClose={() => setShowModal(false)}
            >
              <StyledList>
                <div tw='flex space-x-5'>
                  <ul>
                    <p tw='text-gray-700 text-lg'>Time</p>
                    {times.map((time) => (
                      <li key={time}>{time}</li>
                    ))}
                  </ul>
                  <ul>
                    <p tw='text-gray-700 text-lg'>Price</p>
                    {allPrices?.map((c, i) => (
                      <li key={i}>{c}</li>
                    ))}
                  </ul>
                  <ul>
                    <p tw='text-gray-700 text-lg'>Commission</p>
                    {allCommissions?.map((c, i) => (
                      <li key={i}>{c}</li>
                    ))}
                  </ul>
                </div>
                <div tw='flex space-x-5 pl-4'>
                  <ul>
                    <p tw='text-gray-700 text-lg'>Time</p>
                    {times.map((time) => (
                      <li key={time}>{time}</li>
                    ))}
                  </ul>
                  <ul>
                    <p tw='text-gray-700 text-lg whitespace-nowrap'>Online Price</p>
                    {allOnlinePrices?.map((c, i) => (
                      <li key={i}>{c}</li>
                    ))}
                  </ul>
                  <ul>
                    <p tw='text-gray-700 text-lg  whitespace-nowrap'>Online Commission</p>
                    {allOnlineCommissions?.map((c, i) => (
                      <li key={i}>{c}</li>
                    ))}
                  </ul>
                </div>
              </StyledList>
            </Modal>
          </div>
          <button onClick={handleRemove} className='action'>
            Remove
          </button>
        </div>
      </StyledCard>
    </div>
  );
};

export default AssignedTutorCard;

const StyledCard = styled.div`
  ${tw`mt-5 sm:w-full border flex flex-col items-center justify-center p-8 pb-5 rounded-lg shadow text-center relative h-full`};

  .priceButton {
    ${tw`bg-blue-200 self-center py-1 px-2 rounded text-blue-900 font-medium focus:outline-none`}
  }
  .action {
    ${tw`bg-red-200 text-red-900 font-medium px-3 py-1 rounded focus:outline-none`}
  }
  .badge {
    ${tw`flex justify-between absolute w-full top-0`}
    .type {
      ${tw`bg-yellow-400 text-sm p-1 px-2 rounded-tl-lg`}
    }
  }
  .body {
    ${tw`w-full`}
  }
  h4 {
    ${tw`text-xl uppercase text-gray-600 font-bold mt-5`}
  }
  .info {
    ${tw`mt-2.5 flex flex-col space-y-4 justify-center`}
    .button {
      ${tw`text-sm inline`}
    }

    p {
      ${tw`flex items-center space-x-1 text-lg text-left`}
      b {
        ${tw`ml-0.5 text-gray-600`}
      }
    }
    svg {
      ${tw`mr-1.5 text-xl text-gray-500`}
    }
    .prices {
      ${tw`flex justify-between items-center`}
      span {
        ${tw`border px-2 py-1 text-gray-800`}
      }
    }
  }
`;

const StyledList = styled.div`
  ${tw`flex flex-col space-x-0 space-y-10 md:(flex-row space-x-10 space-y-0 divide-x-2 divide-blue-500) justify-center text-center`}
  ul {
    ${tw`p-2`}
    &:first-child {
      ${tw`text-right`}
      li {
        ${tw`font-normal whitespace-nowrap text-sm md:text-base`}
      }
    }
    p {
      ${tw`border-b`}
    }
    li {
      ${tw`my-1 font-medium`}
    }
  }
`;
