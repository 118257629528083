import React from 'react';
import tw, { styled } from 'twin.macro';
import Avatar from 'react-avatar';
import { Link } from 'react-router-dom';

import { RiMoneyDollarCircleFill } from 'react-icons/ri';
import { FaAddressCard } from 'react-icons/fa';

const TutorCard = ({ id, fullName, balance, address }) => {
  return (
    <Link to={`/tutor/${id}`}>
      <StyledCard>
        <Avatar name={fullName} round={true} size='80' />
        <div className='body'>
          <h4>{fullName}</h4>
          <div className='info'>
            <p>
              <RiMoneyDollarCircleFill />{' '}
              <span>
                Balance: <b>{balance}</b>
              </span>
            </p>
            <p>
              <FaAddressCard />
              <span>
                Address: <b>{address}</b>
              </span>
            </p>
          </div>
        </div>
      </StyledCard>
    </Link>
  );
};

export default TutorCard;

const StyledCard = styled.div`
  ${tw`w-full border flex flex-col items-center justify-center p-8 rounded shadow text-center hover:shadow-xl transition-transform transform hover:-translate-y-0.5 h-full`};

  .body {
    ${tw`w-full`}
  }
  h4 {
    ${tw`text-xl capitalize text-gray-600 font-bold mt-5 `}
  }
  .info {
    ${tw`mt-2.5 flex flex-col justify-center  divide-y divide-gray-100`}
    p {
      ${tw`flex items-center space-x-1 text-lg text-left mt-2`}
      b {
        ${tw`ml-0.5 text-gray-600`}
      }
    }
    svg {
      ${tw`mr-1.5 text-xl text-gray-500`}
    }
  }
`;
