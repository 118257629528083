import StyledInput from "components/common/StyledInput";
import React from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import tw, { styled } from "twin.macro";
import useAlert from "hooks/useAlert";
import Button from "components/common/Button";
import TextArea from "components/common/TextArea";
import { addEvent } from "api";

export default function AddEvent({ CloseModal, fetchEvents }) {
  const { showError, ShowSuccess } = useAlert();
  const queryClient = useQueryClient();

  const onSubmit = async (data) => {
    mutate(data);
  };

  const { mutate, isLoading } = useMutation((event) => addEvent(event), {
    onError: (error) => {
      showError("Something went wrong!");
    },
    onSuccess: async () => {
      ShowSuccess("Event Added");
      CloseModal();
      queryClient.invalidateQueries("allEvents");
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onChange" });

  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)}>
      <div className="tlabel">Date Time</div>
      <input
        type="datetime-local"
        {...register("date", {})}
        className="timepicker"
        label="Date Time"
      />
      <StyledInput
        register={register}
        validation={{ required: "Titile Is required" }}
        error={errors.title}
        name="title"
        label="Event Title"
        placeholder="Ex. Music fundamentals for beginners"
      />
      <StyledInput
        register={register}
        validation={{ required: "Location Is required" }}
        error={errors.location}
        name="location"
        label="Location"
        placeholder="Ex. Online"
      />

      <TextArea
        register={register}
        error={errors.description}
        name="description"
        label="Descripion"
        placeholder="Ex: Description about the event"
      />

      <Button loading={isLoading} text="Add Event" type="submit" />
    </StyledForm>
  );
}

const StyledForm = styled.form`
  ${tw`flex flex-col space-y-2.5`}
  button {
    ${tw`w-full`}
  }
  .timepicker {
    ${tw`mt-0 p-3 border-gray-300 border-2 rounded-md`}
  }
  .tlabel {
    ${tw`p-0 m-0 font-medium uppercase text-xs`}
  }
`;
