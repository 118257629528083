import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import StyledInput from 'components/common/StyledInput';
import tw, { styled } from 'twin.macro';
import ApiCaller from 'api/axios';
import { MdRadioButtonUnchecked, MdRadioButtonChecked } from 'react-icons/md';
import { useDebounce } from 'hooks/useDebounce';

const SearchTutor = ({ selected, setSelected }) => {
  const [searching, setSearching] = useState(false);
  const [tutors, setTutors] = useState(null);

  const [searchTerm, setSearchTerm] = useState('');

  const debouncedSearchTerm = useDebounce(searchTerm, 600);

  const {
    register,
    formState: { errors },
  } = useForm();

  const searchTutor = async (name) => {
    setSelected(null);
    setSearching(true);
    try {
      const { data } = await ApiCaller.get(`/tutor/search?name=${name}`);
      console.log(data);
      setTutors(data);
      setSearching(false);
    } catch (error) {
      setSearching(false);
    }
  };

  const selectTutor = (tutor) => {
    if (tutor === selected) return setSelected(null);
    setSelected(tutor);
  };

  React.useEffect(() => {
    if (debouncedSearchTerm) {
      searchTutor(debouncedSearchTerm);
    } else {
      setTutors([]);
    }
  }, [debouncedSearchTerm]);

  return (
    <StyledSearch selected={selected} searching={searching}>
      <h4>Find a Tutor</h4>
      <div className='searchForm'>
        <StyledInput
          onChange={(e) => setSearchTerm(e.target.value)}
          register={register}
          validation={{ minLength: { value: 2, message: 'too short name' } }}
          error={errors.name}
          name='name'
          placeholder='Search Tutor - Ex: John Doe'
        />
      </div>

      {searching && <div className='text'>searching....</div>}

      {selected && !tutors && (
        <div className='tutor active' tw='mt-5'>
          <div className='info'>
            <p className='name'>{selected.fullName}</p>
            <p>{selected.address}</p>
          </div>
          <MdRadioButtonChecked />
        </div>
      )}

      {tutors?.length === 0 && !searching ? (
        <div className='text notFound'>No Tutor Found!</div>
      ) : (
        <div className='tutorList'>
          {tutors?.map((t, i) => {
            return (
              <div key={i} className={`tutor ${selected === t ? 'active' : ''}`} onClick={() => selectTutor(t)}>
                <div className='info'>
                  <p className='name'>{t.fullName}</p>
                  <p>{t.address}</p>
                </div>
                {selected === t ? <MdRadioButtonChecked /> : <MdRadioButtonUnchecked />}
              </div>
            );
          })}
        </div>
      )}
    </StyledSearch>
  );
};

export default React.memo(SearchTutor);

const StyledSearch = styled.div`
  ${tw`border rounded p-5 mb-5`}

  ${(p) => p.selected && tw`border-green-400 `}

  transition: min-height .4s;

  ${(p) => p.searching && tw`min-height[150px]`}
  h4 {
    ${tw`font-semibold text-2xl mb-2 text-blue-600`}
  }
  .text {
    ${tw`mt-2.5 flex justify-center min-height[150px] items-center border font-semibold text-2xl `}
  }
  .notFound {
    ${tw`text-red-600`}
  }
  .tutorList {
    ${tw`flex flex-col space-y-2.5 mt-2.5`}
  }
  .tutor {
    ${tw`flex justify-between items-center border rounded-xl border-dashed p-5 cursor-pointer border-gray-400 hover:border-blue-400`}
    .name {
      ${tw`text-xl font-medium`}
    }
    svg {
      ${tw`min-w-max text-2xl text-blue-500`}
    }
  }
  .active {
    ${tw`border-blue-600`}
  }
  .searchForm {
    ${tw`flex flex-col space-y-2  sm:(flex-row items-start space-y-1.5 space-x-4)`};
    button {
      ${tw`w-full sm:w-64 mt-1.5 self-start`}
    }
  }
`;
