import { removeChild } from 'api';
import Button from 'components/common/Button';
import Modal from 'components/common/Modal';
import useAlert from 'hooks/useAlert';
import React, { useState } from 'react';
import Avatar from 'react-avatar';
import tw, { styled } from 'twin.macro';
import AddChild from './AddChild';
import EditChild from './EditChild';

const ChildTab = ({ fetchStudent, studentId, child }) => {
  const { ConfirmDialog, showError } = useAlert();

  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedChild, setSelectedChild] = useState(null);

  const closeModal = () => {
    setShowModal(false);
    fetchStudent();
  };

  const closeEditModal = () => {
    setShowEditModal(false);
    fetchStudent();
  };

  const handleRemove = (id) => {
    ConfirmDialog(
      "You won't be able to revert this!",
      'Yes, Delete it!',
      async () => {
        try {
          await removeChild(id);
          fetchStudent();
        } catch (error) {
          console.log(error);
          showError("Can't delete assigned student!");
        }
      }
    );
  };

  return (
    <div>
      <Button onClick={() => setShowModal(true)} text="Add New Child" />
      <Modal
        heading="Add new Child"
        isOpen={showModal}
        onClose={() => setShowModal(false)}
      >
        <AddChild studentId={studentId} closeModal={closeModal} />
      </Modal>

      <Modal
        heading="Update Child"
        isOpen={showEditModal}
        onClose={() => setShowEditModal(false)}
      >
        <EditChild
          studentId={studentId}
          child={selectedChild}
          closeModal={closeEditModal}
        />
      </Modal>

      {(!child || !child.length === 0) && <div>No Children Found</div>}
      <StudentList>
        {child?.map((c) => {
          return (
            <div className="student" key={c.id}>
              {/* <Avatar name={c.name} round={true} size="80" /> */}

              <div style={{ padding: 15, textAlign: "center" }}>
                <p>
                  <b>£{c.bill}<br />£{c.paid}<br />£{c.balance}</b> 
                  <b></b>
                </p>
              </div>

              <div className="info">
                <h3>{c.name}</h3>
                <p>
                  Age: <b>{c.age}</b>
                </p>
                <p>
                  Post Code: <b>{c.postCode}</b>
                </p>
                {c.instrument && (
                  <p>
                    Sent Instrument: <b>{c.instrument}</b>
                  </p>
                )}
                {c.status && (
                  <p>
                    Status: <b>{c.status}</b>
                  </p>
                )}
                <div className="flex">
                  <button onClick={() => handleRemove(c.id)} className="action">
                    Remove
                  </button>
                  <button
                    onClick={() => {
                      setShowEditModal(true);
                      setSelectedChild(c);
                    }}
                    className="edit-action"
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>
          );
        })}
      </StudentList>
    </div>
  );
};

export default ChildTab;

const StudentList = styled.div`
  ${tw`grid xl:grid-cols-2 2xl:grid-cols-3 gap-2.5 my-5`};
  .student {
    ${tw`flex p-5  space-x-5 rounded-lg border items-center justify-between`};
    .info {
      ${tw`flex flex-col`};
      h3 {
        ${tw`text-2xl mb-2 font-semibold text-gray-700`}
      }
      p {
        ${tw`text-lg text-gray-500`}
      }
    }
  }
  .action {
    ${tw`bg-red-200 text-red-900 w-min font-medium px-3 py-1 rounded focus:outline-none`}
  }
  .edit-action {
    ${tw`bg-green-200 text-green-900 w-min font-medium px-3 py-1 rounded focus:outline-none ml-2.5`}
  }
`;
