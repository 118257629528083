import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import Button from 'components/common/Button';
import StyledInput from 'components/common/StyledInput';
import tw, { styled } from 'twin.macro';
import ApiCaller from 'api/axios';
import { getErrorMessage } from 'utils/getErrors';
import useAlert from 'hooks/useAlert';
import SearchTutor from './SearchTutor';
import StyledOptions from 'components/common/StyledOptions';

const AssignTutor = ({ studentId, closeModal, allChild, fetchStudent }) => {
  const [loading, setLoading] = useState(false);
  const { showError, ShowSuccess } = useAlert();
  const [type, setType] = useState(null);

  const [step, setStep] = useState(0);

  const [selectedTutor, setSelectedTutor] = useState(null);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      cid: 'none',
    },
  });

  const fillUA = () => {
    setValue('p1', '10')
    setValue('c1', '6')
    setValue('p2', '12.5')
    setValue('c2', '8.5')
    setValue('p3', '15')
    setValue('c3', '11')
    setValue('p4', '17.5')
    setValue('c4', '12.5')
    setValue('p5', '20')
    setValue('c5', '15')
    setValue('p6', '22.5')
    setValue('c6', '16.5')
    setValue('p7', '25')
    setValue('c7', '18')

  }
  const fillUK = () => {
    setValue('p1', '20')
    setValue('c1', '8')
    setValue('p2', '25')
    setValue('c2', '8')
    setValue('p3', '30')
    setValue('c3', '8')
    setValue('p4', '40')
    setValue('c4', '8')
    setValue('p5', '50')
    setValue('c5', '8')
    setValue('p6', '55')
    setValue('c6', '8')
    setValue('p7', '60')
    setValue('c7', '8')
    // in person
    setValue('pp1', '26')
    setValue('pc1', '12')
    setValue('pp2', '32')
    setValue('pc2', '12')
    setValue('pp3', '38')
    setValue('pc3', '12')
    setValue('pp4', '47')
    setValue('pc4', '13')
    setValue('pp5', '56')
    setValue('pc5', '14')
    setValue('pp6', '63')
    setValue('pc6', '15')
    setValue('pp7', '70')
    setValue('pc7', '16')
  }

  const onFormSubmit = async ({
    cid,
    instrument,
    c1,
    p1,
    p2,
    c2,
    p3,
    c3,
    p4,
    c4,
    p5,
    c5,
    p6,
    c6,
    p7,
    c7,
    pp1,
    pc1,
    pp2,
    pc2,
    pp3,
    pc3,
    pp4,
    pc4,
    pp5,
    pc5,
    pp6,
    pc6,
    pp7,
    pc7,
  }) => {
    if (!selectedTutor) return showError('Select and Tutor');
    if (!type) return showError('Select a type');

    setLoading(true);
    const sanitized = {
      prices: `${pp1},${pp2},${pp3},${pp4},${pp5},${pp6},${pp7}`,
      commissions: `${pc1},${pc2},${pc3},${pc4},${pc5},${pc6},${pc7}`,
      onlinePrices: `${p1},${p2},${p3},${p4},${p5},${p6},${p7}`,
      onlineCommissions: `${c1},${c2},${c3},${c4},${c5},${c6},${c7}`,
      cid,
      type,
      sid: studentId,
      tid: selectedTutor.id,
      instrument,
    };

    try {
      const res = await ApiCaller.post(`/student/assign`, { ...sanitized });
      if (res.status === 200) {
        ShowSuccess('Tutor Assigned!');
        setLoading(false);
        fetchStudent();
        closeModal();
      }
    } catch (error) {
      setLoading(false);
      const errorMessage = getErrorMessage(error);
      showError(errorMessage);
    }
  };

  return (
    <>
      <Stepper>
        <div className='step'>
          <p className={`number ${step === 0 && 'active'}`}>1</p>
          <p>Find a tutor</p>
        </div>
        <div className='step'>
          <p className={`number ${step === 1 && 'active'}`}>2</p>
          <p>Assign to a student</p>
        </div>
      </Stepper>

      {step === 0 ? (
        <StepOne>
          <SearchTutor selected={selectedTutor} setSelected={setSelectedTutor} />
          <Button className='next' disabled={!selectedTutor} onClick={() => setStep(1)} text='Next' />
        </StepOne>
      ) : (
        <StyledForm onSubmit={handleSubmit(onFormSubmit)}>
          <StyledOptions
            register={register}
            validation={{ required: true, validate: (v) => v !== 'none' }}
            error={errors.cid}
            name='cid'
            items={allChild}
            label='Select Child'
          />

          <p tw='text-gray-900 uppercase text-xs font-medium'>Select Prices & Commissions</p>

          <div className="auto-prices">
            <button type='button' onClick={fillUA}>Fill with UA-Prices</button>
            <button type='button' onClick={fillUK}>Fill with UK-Prices</button>
          </div>

          <div className='type-container'>
            <RadioItem onClick={() => setType('Online')} className='item' isActive={type === 'Online'}>
              Online
            </RadioItem>
            <RadioItem onClick={() => setType('In-Person')} className='item' isActive={type === 'In-Person'}>
              In Person
            </RadioItem>
            <RadioItem onClick={() => setType('Both')} className='item' isActive={type === 'Both'}>
              Both
            </RadioItem>
          </div>

          <div tw='flex' className='inputs'>
            {(type === 'Online' || type === 'Both') && (
              <div tw='w-1/2 pr-2.5'>
                <p tw='text-gray-900 uppercase text-xs font-medium mb-2 pb-2 border-b'>Online Prices & Commissions</p>
                <div tw='flex space-x-1'>
                  <StyledInput
                    type='number'
                    step='0.01'
                    error={errors.p1}
                    register={register}
                    name='p1'
                    placeholder='30 minutes'
                    label='30 minutes'
                  />
                  <StyledInput
                    type='number'
                    step='0.01'
                    error={errors.c1}
                    register={register}
                    name='c1'
                    placeholder='Commission'
                    label='Commission'
                  />
                </div>
                <div tw='flex space-x-1'>
                  <StyledInput
                    type='number'
                    step='0.01'
                    error={errors.p2}
                    register={register}
                    name='p2'
                    placeholder='45 minutes'
                    label='45 minutes'
                  />
                  <StyledInput
                    type='number'
                    step='0.01'
                    error={errors.c2}
                    register={register}
                    name='c2'
                    placeholder='Commission'
                    label='Commission'
                  />
                </div>
                <div tw='flex space-x-1'>
                  <StyledInput
                    type='number'
                    step='0.01'
                    error={errors.p3}
                    register={register}
                    name='p3'
                    placeholder='1 Hour'
                    label='1 Hour'
                  />
                  <StyledInput
                    type='number'
                    step='0.01'
                    error={errors.c3}
                    register={register}
                    name='c3'
                    placeholder='Commission'
                    label='Commission'
                  />
                </div>
                <div tw='flex space-x-1'>
                  <StyledInput
                    type='number'
                    error={errors.p4}
                    step='0.01'
                    register={register}
                    name='p4'
                    placeholder='1 hour 15 minutes'
                    label='1 hour 15 minutes'
                  />
                  <StyledInput
                    type='number'
                    step='0.01'
                    error={errors.c4}
                    register={register}
                    name='c4'
                    placeholder='Commission'
                    label='Commission'
                  />
                </div>
                <div tw='flex space-x-1'>
                  <StyledInput
                    type='number'
                    error={errors.p5}
                    register={register}
                    step='0.01'
                    name='p5'
                    placeholder='1 hours and 30 minutes'
                    label='1 hours and 30 minutes'
                  />
                  <StyledInput
                    type='number'
                    step='0.01'
                    error={errors.c5}
                    register={register}
                    name='c5'
                    placeholder='Commission'
                    label='Commission'
                  />
                </div>
                <div tw='flex space-x-1'>
                  <StyledInput
                    type='number'
                    error={errors.p6}
                    step='0.01'
                    register={register}
                    name='p6'
                    placeholder='1 hours and 45 minutes'
                    label='1 hours and 45 minutes'
                  />
                  <StyledInput
                    type='number'
                    step='0.01'
                    error={errors.c6}
                    register={register}
                    name='c6'
                    placeholder='Commission'
                    label='Commission'
                  />
                </div>
                <div tw='flex space-x-1'>
                  <StyledInput
                    type='number'
                    step='0.01'
                    error={errors.p7}
                    register={register}
                    name='p7'
                    placeholder='2 Hours'
                    label='2 Hours'
                  />
                  <StyledInput
                    type='number'
                    step='0.01'
                    error={errors.c7}
                    register={register}
                    name='c7'
                    placeholder='Commission'
                    label='Commission'
                  />
                </div>
              </div>
            )}
            {(type === 'In-Person' || type === 'Both') && (
              <div tw='w-1/2 pr-2.5'>
                <p tw='text-gray-900 uppercase text-xs font-medium mb-2 pb-2 border-b'>
                  In Person Prices & Commissions
                </p>
                <div tw='flex space-x-1'>
                  <StyledInput
                    type='number'
                    error={errors.pp1}
                    register={register}
                    name='pp1'
                    step='0.01'
                    placeholder='30 minutes (In-Person)'
                    label='30 minutes (In-Person)'
                  />
                  <StyledInput
                    type='number'
                    error={errors.pc1}
                    register={register}
                    name='pc1'
                    step='0.01'
                    placeholder='Commission'
                    label='Commission'
                  />
                </div>
                <div tw='flex space-x-1'>
                  <StyledInput
                    type='number'
                    error={errors.pp2}
                    register={register}
                    name='pp2'
                    step='0.01'
                    placeholder='45 minutes (In-Person)'
                    label='45 minutes (In-Person)'
                  />
                  <StyledInput
                    type='number'
                    error={errors.pc2}
                    register={register}
                    name='pc2'
                    step='0.01'
                    placeholder='Commission'
                    label='Commission'
                  />
                </div>
                <div tw='flex space-x-1'>
                  <StyledInput
                    type='number'
                    error={errors.pp3}
                    step='0.01'
                    register={register}
                    name='pp3'
                    placeholder='1 Hour (In-Person)'
                    label='1 Hour (In-Person)'
                  />
                  <StyledInput
                    type='number'
                    error={errors.pc3}
                    register={register}
                    step='0.01'
                    name='pc3'
                    placeholder='Commission'
                    label='Commission'
                  />
                </div>
                <div tw='flex space-x-1'>
                  <StyledInput
                    type='number'
                    step='0.01'
                    error={errors.pp4}
                    register={register}
                    name='pp4'
                    placeholder='1 hour 15 minutes (In-Person)'
                    label='1 hour 15 minutes (In-Person)'
                  />
                  <StyledInput
                    type='number'
                    error={errors.pc4}
                    register={register}
                    name='pc4'
                    placeholder='Commission'
                    label='Commission'
                    step='0.01'
                  />
                </div>
                <div tw='flex space-x-1'>
                  <StyledInput
                    type='number'
                    error={errors.pp5}
                    register={register}
                    step='0.01'
                    name='pp5'
                    placeholder='1 hours and 30 minutes (In-Person)'
                    label='1 hours and 30 minutes (In-Person)'
                  />
                  <StyledInput
                    type='number'
                    error={errors.pc5}
                    step='0.01'
                    register={register}
                    name='pc5'
                    placeholder='Commission'
                    label='Commission'
                  />
                </div>
                <div tw='flex space-x-1'>
                  <StyledInput
                    type='number'
                    error={errors.pp6}
                    register={register}
                    step='0.01'
                    name='pp6'
                    placeholder='1 hours and 45 minutes (In-Person)'
                    label='1 hours and 45 minutes (In-Person)'
                  />
                  <StyledInput
                    type='number'
                    error={errors.pc6}
                    register={register}
                    step='0.01'
                    name='pc6'
                    placeholder='Commission'
                    label='Commission'
                  />
                </div>
                <div tw='flex space-x-1'>
                  <StyledInput
                    type='number'
                    error={errors.pp7}
                    register={register}
                    name='pp7'
                    step='0.01'
                    placeholder='2 Hours (In-Person)'
                    label='2 Hours (In-Person)'
                  />
                  <StyledInput
                    type='number'
                    error={errors.pc7}
                    step='0.01'
                    register={register}
                    name='pc7'
                    placeholder='Commission'
                    label='Commission'
                  />
                </div>
              </div>
            )}
          </div>

          <StyledInput
            register={register}
            validation={{ required: 'Instrument is Required!' }}
            error={errors.commission}
            name='instrument'
            label='instrument'
            placeholder='Ex: Piano'
          />
          <div className='actions'>
            <Button loading={loading} text='Assign Tutor' type='submit' />
            <Button type='button' className='back' onClick={() => setStep(0)} text='Back' />
          </div>
        </StyledForm>
      )}
    </>
  );
};

export default AssignTutor;

const StyledForm = styled.form`
  ${tw`flex flex-col space-y-5`};
  button {
    ${tw`self-start`}
  }
  .prices {
    ${tw`flex space-x-2`}
  }

  .inputs {
    input {
      ${tw`placeholder:text-sm`}
    }
    label {
      font-size: 10px
    }
  }
  .auto-prices {
    ${tw`my-2`}
    button {
      ${tw`focus:outline-none border border-purple-500 mr-5 px-2 py-1 rounded hover:bg-blue-100 text-sm`}
    }
  }
  .type-container {
    ${tw`flex justify-between pb-2.5 space-x-3`}
  }
  .actions {
    ${tw`flex flex-col space-y-2.5 items-center justify-center md:(flex-row justify-between space-y-0)`}
    button {
      ${tw`w-full md:w-48`}
    }
    .back {
      ${tw`bg-green-500`}
    }
  }
`;

const RadioItem = styled.div`
  ${tw`cursor-pointer border rounded w-full text-center p-3 border-gray-300 hover:(border-gray-700)`}
  ${(p) => p.isActive && tw`border-gray-700 bg-blue-100 font-semibold`}
`;

const Stepper = styled.div`
  ${tw`flex justify-between p-5 relative`};
  &:after {
    content: '';
    ${tw`absolute w-full left-0 h-0.5 bg-blue-400 top-8`}
  }
  .step {
    ${tw`flex flex-col items-center justify-center z-10`}
    .number {
      ${tw`border  border-gray-500 rounded-full h-7 w-7 flex items-center justify-center bg-white`}
    }
    p {
      ${tw`text-gray-700`}
    }
    .active {
      ${tw`bg-blue-500 text-white border-blue-500`}
    }
  }
`;

const StepOne = styled.div`
  ${tw`flex flex-col`};
  button {
    ${tw`self-end w-48`}
  }
  .next {
    ${tw`bg-green-500 disabled:(bg-gray-200 text-gray-800)`}
  }
`;
