import React from "react";
import { FaBookReader } from "react-icons/fa";
import { GiTeacher } from "react-icons/gi";
import { BsBookHalf } from "react-icons/bs";
import { CgToday } from "react-icons/cg";
import tw, { styled } from "twin.macro";
import StatCard from "components/cards/StatCard";
import Layout from "components/common/Layout";
import PageTitle from "components/common/PageTitle";
import { useQuery } from "react-query";
import { getDashboardData } from "./../../api/index";
import FullPageSkeleton from "components/common/Skeletons";

const HomePage = () => {
  const { data, isLoading } = useQuery("dashboard", getDashboardData);

  console.log({ data, isLoading });

  if (isLoading) {
    return (
      <Layout>
        <FullPageSkeleton />
      </Layout>
    );
  }

  return (
    <Layout>
      <StyledDashboard>
        <PageTitle text="Dashboard" />
        <div className="cards">
          <StatCard
            to="/students"
            title={data.students}
            subtitle="Students"
            icon={<FaBookReader />}
          />
          <StatCard
            to="/tutors"
            title={data.tutors}
            subtitle="Teachers"
            icon={<GiTeacher />}
          />
          <StatCard
            title={data.lessons}
            subtitle="Lessons"
            icon={<BsBookHalf />}
          />
          <StatCard
            title={data.lessonToday}
            subtitle="Today"
            icon={<CgToday />}
          />
        </div>
      </StyledDashboard>
    </Layout>
  );
};

export default HomePage;

const StyledDashboard = styled.div`
  ${tw`flex flex-col w-full`};
  .cards {
    ${tw`grid md:grid-cols-2 xl:grid-cols-4 w-full gap-3 md:gap-3 mb-5`}
  }
`;
