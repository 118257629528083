import { getErrorMessage } from 'utils/getErrors';
import tw, { styled } from 'twin.macro';
import Layout from './Layout';

const ErrorHandler = ({ error }) => {
  const errorMessage = getErrorMessage(error);
  return (
    <Layout>
      <StyledError>
        <h1>{errorMessage}</h1>
      </StyledError>
    </Layout>
  );
};

export default ErrorHandler;

const StyledError = styled.div`
  ${tw`flex mt-24 py-5 items-center justify-center bg-red-100 rounded-md`};
  h1 {
    ${tw`text-3xl font-bold text-red-700`}
  }
`;
