import { updateChild } from 'api';
import Button from 'components/common/Button';
import StyledInput from 'components/common/StyledInput';
import StyledOptions from 'components/common/StyledOptions';
import useAlert from 'hooks/useAlert';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import tw, { styled } from 'twin.macro';
import { childStatuses } from 'utils/constants';
import { getErrorMessage } from 'utils/getErrors';

const EditChild = ({ child, closeModal }) => {
  const [loading, setLoading] = useState(false);
  const { showError, ShowSuccess } = useAlert();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      ...child,
      postcode: child.postCode,
      status: child.status || 'None',
    },
  });

  const onFormSubmit = async (data) => {
    setLoading(true);
    try {
      await updateChild({ ...data });
      ShowSuccess('Child Updated!');
      setLoading(false);
      closeModal();
    } catch (error) {
      setLoading(false);
      const errorMessage = getErrorMessage(error);
      showError(errorMessage);
    }
  };

  return (
    <>
      <StyledForm onSubmit={handleSubmit(onFormSubmit)}>
        <StyledInput
          register={register}
          validation={{ required: 'Child Name is Required!' }}
          error={errors.name}
          name="name"
          label="Child Name"
          placeholder="Ex: John Doe"
        />
        <StyledInput
          register={register}
          validation={{ required: 'Date of Birth is Required!' }}
          error={errors.age}
          name="age"
          type="number"
          label="Child Age"
          placeholder="Ex: 12"
        />
        <StyledOptions
          noDefault
          register={register}
          error={errors.status}
          name="status"
          items={childStatuses}
          label="Select Status"
        />
        <StyledInput
          register={register}
          error={errors.instrument}
          name="instrument"
          type="text"
          label="Sent Instrument"
          placeholder="Ex: Y"
        />
        <StyledInput
          register={register}
          name="postcode"
          label="Post Code"
          placeholder="Ex: 121212"
        />
        <Button loading={loading} text="Update Child" type="submit" />
      </StyledForm>
    </>
  );
};

export default EditChild;

const StyledForm = styled.form`
  ${tw`flex flex-col space-y-5`};
  button {
    ${tw`self-start`}
  }
  .postCodeContainer {
    ${tw``}
  }
`;
