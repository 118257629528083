import { getAllStudents } from 'api';
import ApiCaller from 'api/axios';
import StudentCard from 'components/cards/StudentCard';
import Button from 'components/common/Button';
import ErrorHandler from 'components/common/ErrorHandler';
import Layout from 'components/common/Layout';
import Modal from 'components/common/Modal';
import PageTitle from 'components/common/PageTitle';
import Pagination from 'components/common/Pagination';
import { CardLoading } from 'components/common/Skeletons';
import { useDebounce } from 'hooks/useDebounce';
import React, { useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import tw, { styled } from 'twin.macro';
import AddStudent from './AddStudent';

const HomePage = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [page, setPage] = React.useState(1);

  // Searching states
  const [isSearching, setIsSearching] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 600);
  const [searchedStudents, setSearchedStudents] = useState([]);
  const [loadingSearch, setLoadingSearch] = useState(false);

  const queryClient = useQueryClient();

  const { isLoading, data, error, refetch } = useQuery(
    ['allStudents', page],
    () => getAllStudents(page),
    {
      keepPreviousData: true,
      staleTime: 5000, 
    }
  );
  const closeModal = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (data?.hasMore) {
      queryClient.prefetchQuery(['allStudents', page + 1], () =>
        getAllStudents(page + 1)
      );
    }
  }, [data, page, queryClient]);

  const handleSearch = async (term) => {
    try {
      const { data } = await ApiCaller.get(`/student/search?name=${term}`);
      console.log(data);
      setSearchedStudents(data);
      setLoadingSearch(false);
    } catch (error) {
      setSearchedStudents([]);
      setLoadingSearch(false);
    }
  };

  useEffect(() => {
    if (debouncedSearchTerm && debouncedSearchTerm.length > 2) {
      setIsSearching(true);
      setLoadingSearch(true);
      handleSearch(debouncedSearchTerm);
    } else {
      setIsSearching(false);
    }
  }, [debouncedSearchTerm]);

  if (isLoading) {
    return (
      <Layout>
        <CardLoading />
      </Layout>
    );
  }

  if (error) {
    return <ErrorHandler error={error} />;
  }

  const RenderSearching = (
    <div>
      {loadingSearch ? (
        'Searching:...'
      ) : (
        <StudentList>
          {searchedStudents.length > 0 ? (
            searchedStudents?.map((student) => (
              <StudentCard key={student.id} {...student} />
            ))
          ) : (
            <p tw="text-red-700 font-medium">No Student found ☹</p>
          )}
        </StudentList>
      )}
    </div>
  );

  return (
    <Layout>
      <StyledDashboard>
        <div className="head">
          <PageTitle text="All Students" />
          <Button text="Add a Student" onClick={() => setIsOpen(true)} />
          <Modal heading="Add a Student" isOpen={isOpen} onClose={closeModal}>
            <AddStudent
              closeModal={closeModal}
              fetchStudents={() => refetch()}
            />
          </Modal>
        </div>
        <input
          tw="py-2.5 px-4 border my-2.5 rounded text-lg"
          placeholder="Search student..."
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          name="search"
        />
        {isSearching ? (
          RenderSearching
        ) : (
          <>
            <StudentList>
              {data.students?.map((student, i) => (
                <StudentCard key={student.id} {...student} />
              ))}
            </StudentList>
            <Pagination
              currentPage={page}
              totalPage={data.pagination.totalPages}
              setPage={setPage}
            />
          </>
        )}
      </StyledDashboard>
    </Layout>
  );
};

export default HomePage;

const StyledDashboard = styled.div`
  ${tw`flex flex-col pb-10`};
  .head {
    ${tw`flex justify-between items-center border-b border-gray-300 pb-3`}
    h1 {
      ${tw`border-0 p-0 m-0`}
    }
  }
`;

const StudentList = styled.div`
  ${tw`my-5 grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5 `}
`;
