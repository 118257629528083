import React, { useEffect } from 'react';
import 'twin.macro';
import FullPageSkeleton from 'components/common/Skeletons';
import { useAuth } from 'context/AuthContext';
import { AuthenticatedRoutes, UnAuthenticatedRoutes } from 'router';

const App = () => {
  const { init, user, actions } = useAuth();

  useEffect(() => {
    actions.checkUser();
  }, []);

  if (!init) return <FullPageSkeleton />;

  return <>{user ? <AuthenticatedRoutes /> : <UnAuthenticatedRoutes />}</>;
};

export default App;
