import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';
import { AiFillCloseCircle } from 'react-icons/ai';
import tw, { styled } from 'twin.macro';

const isString = (arg) => typeof arg === 'string';

function Modal({ onClose, isOpen, children, heading }) {
  const onContentAreaClick = (e) => {
    e.stopPropagation();
  };

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
      document.addEventListener('keydown', (e) => {
        e.key === 'Escape' && onClose();
      });
    } else {
      document.body.style.overflow = 'unset';
    }
    return () => {
      document.body.style.overflow = 'unset';
      document.removeEventListener('keydown', (e) => e);
    };
  }, [isOpen, onClose]);

  if (isOpen) {
    return createPortal(
      <Overlay>
        <Content className='modal-content' onClick={onContentAreaClick}>
          <Header className='modal-header'>
            {isString(heading) ? <h2 className='heading-text'>{heading}</h2> : heading}
            <AiFillCloseCircle onClick={onClose} />
          </Header>
          <Main className='modal-main'>{children}</Main>
        </Content>
      </Overlay>,
      document.getElementById('modal-root')
    );
  } else {
    return null;
  }
}

export default Modal;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.8);
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 50;
`;
const Content = styled.div`
  background-color: white;
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 2000;
  min-width: 300px;
  max-width: 1000px;
  min-height: 300px;
  margin: 70px auto;
  border-radius: 24px;
  box-shadow: 0 0 30px 0 rgba(33, 38, 59, 0.16);
  animation: popup 0.3s ease;

  @keyframes popup {
    from {
      opacity: 0;
      transform: translateY(-50px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;
const Header = styled.div`
  ${tw`flex items-center justify-between text-gray-600`}
  padding: 24px;
  border-bottom: solid 1px #c8c9ce;
  .heading-text {
    ${tw`font-sans font-bold text-lg md:text-xl`}
  }
  svg {
    ${tw`text-3xl hover:text-red-500 cursor-pointer`}
  }
`;
const Main = styled.div`
  ${tw`px-8 py-4 md:(px-14 py-6) sm:(px-10 py-5) h-full z-10 overflow-x-auto`}
`;
