import ApiCaller from 'api/axios';
import Button from 'components/common/Button';
import StyledInput from 'components/common/StyledInput';
import StyledOptions from 'components/common/StyledOptions';
import useAlert from 'hooks/useAlert';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import tw, { styled } from 'twin.macro';
import { childStatuses } from 'utils/constants';
import { getErrorMessage } from 'utils/getErrors';

const AddChild = ({ studentId, closeModal }) => {
  const [loading, setLoading] = useState(false);
  const { showError, ShowSuccess } = useAlert();
  const [showPostCode, setShowPostCode] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      status: 'none',
    },
  });

  const onFormSubmit = async (data) => {
    setLoading(true);
    try {
      const res = await ApiCaller.post(`/student/addchild?id=${studentId}`, {
        ...data,
      });
      if (res.status === 200) {
        ShowSuccess('Child Added!');
        setLoading(false);
        closeModal();
      }
    } catch (error) {
      setLoading(false);
      const errorMessage = getErrorMessage(error);
      showError(errorMessage);
    }
  };

  return (
    <>
      <StyledForm onSubmit={handleSubmit(onFormSubmit)}>
        <StyledInput
          register={register}
          validation={{ required: 'Child Name is Required!' }}
          error={errors.name}
          name="name"
          label="Child Name"
          placeholder="Ex: John Doe"
        />
        <StyledInput
          register={register}
          validation={{ required: 'Date of Birth is Required!' }}
          error={errors.age}
          name="age"
          type="number"
          label="Child Age"
          placeholder="Ex: 12"
        />
        <StyledOptions
          register={register}
          error={errors.status}
          name="status"
          items={childStatuses}
          label="Select Status"
        />
        <StyledInput
          register={register}
          error={errors.instrument}
          name="instrument"
          type="text"
          label="Sent Instrument"
          placeholder="Ex: Y"
        />
        <div className="postCodeContainer">
          <input
            type="checkbox"
            id="post-code"
            onChange={() => setShowPostCode((p) => !p)}
            value={showPostCode}
          />{' '}
          <label htmlFor="post-code">Different Postcode?</label>
        </div>
        {showPostCode && (
          <StyledInput
            register={register}
            name="postcode"
            label="Post Code"
            placeholder="Ex: 121212"
          />
        )}
        <Button loading={loading} text="Add Child" type="submit" />
      </StyledForm>
    </>
  );
};

export default AddChild;

const StyledForm = styled.form`
  ${tw`flex flex-col space-y-5`};
  button {
    ${tw`self-start`}
  }
  .postCodeContainer {
    ${tw``}
  }
`;
