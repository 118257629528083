import React from 'react';
import tw, { styled } from 'twin.macro';

const PageTitle = ({ text }) => {
  return <StyledTitle>{text}</StyledTitle>;
};

export default PageTitle;

const StyledTitle = styled.h1`
  ${tw`text-2xl sm:text-3xl font-bold my-5 text-purple-700 border-b pb-5`}
`;
