import tw, { styled } from 'twin.macro';
import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from 'context/AuthContext';
import { MdDashboard, MdEvent } from 'react-icons/md';
import { FaUserAlt } from 'react-icons/fa';
import { GiTeacher } from 'react-icons/gi';
import { BiMenuAltRight, BiMenu, BiLogOut } from 'react-icons/bi';

const Sidebar = ({ isOpen, setIsOpen }) => {
  const { user, actions } = useAuth();

  const logout = () => {
    actions.logoutUser();
  };

  const toggleMenu = () => {
    setIsOpen((p) => !p);
  };

  return (
    <SideMenu isOpen={isOpen}>
      <div className='top'>
        <div className='toggler'>
          <span>
            <Link to='/'>Lycaeum Music</Link>
          </span>
          {isOpen ? <BiMenuAltRight onClick={toggleMenu} /> : <BiMenu onClick={toggleMenu} />}
        </div>
        <Link to='/'>
          <MdDashboard />
          <span>Dashboard</span>
        </Link>
        <Link to='/students'>
          <FaUserAlt />
          <span>Students</span>
        </Link>
        <Link to='/tutors'>
          <GiTeacher />
          <span>Tutors</span>
        </Link>
        <Link to='/events'>
          <MdEvent/>
          <span>Events</span>
        </Link>
      </div>
      <div className='bottom'>
        <span>{user.fullName}</span>
        <BiLogOut title='Logout' onClick={logout} />
      </div>
    </SideMenu>
  );
};

export default Sidebar;

const SideMenu = styled.div`
  ${tw`shadow-2xl fixed left-0 top-0 bottom-0 bg-gray-800 text-white flex flex-col justify-between items-center  py-6 z-20 px-2.5`};

  transition: width 0.25s;

  ${(p) => (p.isOpen ? tw`w-64` : tw`w-16 overflow-hidden`)}

  .top {
    ${tw`flex flex-col items-center space-y-4`}
    span {
      ${tw`whitespace-nowrap`}
      transition: 0.4s;
      ${(p) => (p.isOpen ? tw`inline-block` : tw`hidden`)}
    }
    a {
      ${tw`w-full p-2.5 px-4 text-xl flex items-center transition-all duration-150 space-x-2 hover:(bg-white text-gray-800) rounded-xl`}
      svg {
        ${tw`inline-block`}
      }
    }
  }
  .toggler {
    ${tw`flex justify-between items-center space-x-4`}
    ${(p) => !p.isOpen && tw`space-x-0`}
    a {
      ${tw`text-xl text-transparent bg-clip-text bg-gradient-to-br from-pink-400 to-blue-300 hover:(bg-gray-800 text-white) font-bold`}
    }
    svg {
      ${tw`text-3xl cursor-pointer`}
    }
  }

  .bottom {
    ${tw`flex justify-between items-center space-x-4`}
    ${(p) => !p.isOpen && tw`space-x-0`}
    svg {
      ${tw`text-3xl cursor-pointer hover:text-red-500`}
    }
    span {
      ${tw`whitespace-nowrap`}
      transition: 0.4s;
      ${(p) => (p.isOpen ? tw`inline-block` : tw`hidden`)}
    }
  }
`;
