import { createContext, useState, useContext } from 'react';
import ApiCaller from 'api/axios';
const authContext = createContext();

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [init, setInit] = useState(false);

  const loginUser = async (userData) => {
    try {
      const { data } = await ApiCaller.post('/auth/login', { ...userData });
      if (data.role === 'Admin') {
        localStorage.setItem('token', data.token);
        setUser(data);
      } else {
        localStorage.removeItem('token');
        throw Error;
      }
    } catch (error) {
      localStorage.removeItem('token');
      throw Error;
    }
  };

  const checkUser = async () => {
    const token = localStorage.getItem('token');
    if (!token) return setInit(true);

    try {
      const { data } = await ApiCaller.get('/auth');
      if (data?.role === 'Admin') {
        setUser(data);
      } else {
        localStorage.removeItem('token');
        throw Error;
      }
    } catch (error) {
      console.log('removing token');
      localStorage.removeItem('token');
    } finally {
      setInit(true);
    }
  };

  const logoutUser = () => {
    console.log('removing token');
    localStorage.removeItem('token');
    window.location.reload();
  };

  const actions = { loginUser, logoutUser, checkUser };

  return <authContext.Provider value={{ init, user, actions }}>{children}</authContext.Provider>;
};

export default AuthProvider;

export const useAuth = () => {
  return useContext(authContext);
};
