import React from 'react';
import tw, { styled } from 'twin.macro';

const StyledInput = (props) => {
  const { type, name, label, register, validation, error, ...rest } = props;
  return (
    <StyledContent error={error}>
      {label && <label htmlFor={label}>{label}</label>}
      <input {...register(name, validation)} id={label} type={type || 'text'} {...rest} />
      <span>{error && error.message}</span>
    </StyledContent>
  );
};

export default StyledInput;

const StyledContent = styled.div`
  ${tw`w-full`}
  label {
    ${tw`text-gray-900 uppercase text-xs font-medium`}
  }
  input {
    ${tw`mt-1 w-full border-2 border-gray-300 focus:(outline-none border-gray-700) px-4 py-2.5 text-base md:text-lg rounded-md`};
    ${(p) => p.error && tw`border border-red-300`}
  }
  span {
    ${tw`text-red-500 text-xs`}
  }
`;
