import React, { useState } from 'react';
import { useAuth } from 'context/AuthContext';
import Swal from 'sweetalert2';
import { useHistory } from "react-router-dom"

import tw, { styled } from 'twin.macro';
import Button from 'components/common/Button';

const Login = () => {
  const { actions } = useAuth();
  const history = useHistory()
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (email === '' || password === '') {
      return Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'You need to provide email and password!',
      });
    }
    try {
      await actions.loginUser({ email, password });
    }
    catch (error) {
      console.log('Error', error);
      setLoading(false);
      return Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'You are not authorized to login here!',
      });
    }
    finally {
      window.location.reload(false)
      setLoading(false)
    }
  };

  return (
    <StyledLogin>
      <form onSubmit={handleLogin}>
        <h2>Sign in to your account</h2>
        <div>
          <label htmlFor='email'>Email</label>
          <input
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            type='text'
            name='email'
            id='email'
            placeholder='Enter your Email'
          />
        </div>
        <div>
          <label htmlFor='password'>password</label>
          <input
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            type='password'
            name='password'
            id='password'
            placeholder='Enter your password'
          />
        </div>
        <Button type='submit' text={loading ? 'Authenticating...' : 'Login'} />
      </form>
    </StyledLogin>
  );
};

export default Login;

const StyledLogin = styled.div`
  ${tw`flex justify-center items-center min-h-screen bg-center bg-cover`};
  background: url('/images/login-bg.jpg');

  form {
    ${tw`m-2 border-2 border-gray-600  bg-white shadow-2xl flex flex-col space-y-5 p-5 py-10 lg:(p-10 py-16) rounded`}
    width: 500px;

    h2 {
      ${tw`text-center text-2xl md:text-3xl font-extrabold text-gray-900`}
    }
    label {
      ${tw`block mb-2 text-gray-500`}
    }
    input {
      ${tw`appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-800 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 text-sm md:text-base font-medium placeholder:font-normal`}
    }
  }
`;
