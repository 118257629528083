import { getAllTutors } from 'api';
import ApiCaller from 'api/axios';
import TutorCard from 'components/cards/TutorCard';
import Button from 'components/common/Button';
import ErrorHandler from 'components/common/ErrorHandler';
import Layout from 'components/common/Layout';
import Modal from 'components/common/Modal';
import PageTitle from 'components/common/PageTitle';
import Pagination from 'components/common/Pagination';
import { CardLoading } from 'components/common/Skeletons';
import { useDebounce } from 'hooks/useDebounce';
import React, { useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import tw, { styled } from 'twin.macro';
import AddTutor from './AddTutor';

const TutorPage = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [page, setPage] = React.useState(1);
  const [isSearching, setIsSearching] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 600);
  const [searchedTutors, setSearchedTutors] = useState([]);
  const [loadingSearch, setLoadingSearch] = useState(false);

  const queryClient = useQueryClient();

  const { isLoading, data, error, refetch } = useQuery(
    ['allTutors', page],
    () => getAllTutors(page),
    {
      keepPreviousData: true,
      staleTime: 5000,
    }
  );

  const closeModal = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (data?.hasMore) {
      queryClient.prefetchQuery(['allTutors', page + 1], () =>
        getAllTutors(page + 1)
      );
    }
  }, [data, page, queryClient]);

  const handleSearch = async (term) => {
    try {
      const { data } = await ApiCaller.get(`/tutor/search?name=${term}`);
      console.log(data);
      setSearchedTutors(data);
      setLoadingSearch(false);
    } catch (error) {
      setSearchedTutors([]);
      setLoadingSearch(false);
    }
  };

  useEffect(() => {
    if (debouncedSearchTerm && debouncedSearchTerm.length > 2) {
      setIsSearching(true);
      setLoadingSearch(true);
      handleSearch(debouncedSearchTerm);
    } else {
      setIsSearching(false);
    }
  }, [debouncedSearchTerm]);

  if (isLoading) {
    return (
      <Layout>
        <CardLoading />
      </Layout>
    );
  }

  if (error) {
    return <ErrorHandler error={error} />;
  }

  const RenderSearching = (
    <div>
      {loadingSearch ? (
        'Searching:...'
      ) : (
        <TutorList>
          {searchedTutors.length > 0 ? (
            searchedTutors?.map((tutor) => (
              <TutorCard key={tutor.id} {...tutor} />
            ))
          ) : (
            <p tw="text-red-700 font-medium">No Tutor found ☹</p>
          )}
        </TutorList>
      )}
    </div>
  );

  return (
    <Layout>
      <StyledDashboard>
        <div className="head">
          <PageTitle text="All Tutors" />
          <Button text="Add a Tutor" onClick={() => setIsOpen(true)} />
          <Modal heading="Add a Tutor" isOpen={isOpen} onClose={closeModal}>
            <AddTutor closeModal={closeModal} fetchTutors={refetch} />
          </Modal>
        </div>
        <input
          tw="py-2.5 px-4 border my-2.5 rounded text-lg"
          placeholder="Search tutor..."
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          name="search"
        />
        {isSearching ? (
          RenderSearching
        ) : (
          <>
            <TutorList>
              {data.tutors?.map((tutor) => (
                <TutorCard key={tutor.id} {...tutor} />
              ))}
            </TutorList>
            <Pagination
              currentPage={page}
              totalPage={data.pagination.totalPages}
              setPage={setPage}
            />
          </>
        )}
      </StyledDashboard>
    </Layout>
  );
};

export default TutorPage;

const StyledDashboard = styled.div`
  ${tw`flex flex-col mb-20`};
  .head {
    ${tw`flex justify-between items-center border-b border-gray-300 pb-3`}
    h1 {
      ${tw`border-0 p-0 m-0`}
    }
  }
`;

const TutorList = styled.div`
  ${tw`my-5 grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5 `}
`;
