import React from 'react';
import tw, { styled } from 'twin.macro';
import Avatar from 'react-avatar';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-query';

import { RiMoneyDollarCircleFill } from 'react-icons/ri';
import { FaAddressBook } from 'react-icons/fa';
import { getStudentBalance } from 'api';

const StudentCard = ({ id, fullname, status, postCode }) => {

  const { isLoading, data: balance, error, refetch } = useQuery([`Balance`, id], () => getStudentBalance(id));

  return (
    <Link to={`/student/${id}`}>
      <StyledCard>
        <Avatar name={fullname} round={true} size='80' />
        <div className='body'>
          {status && <p className='status'>{status}</p>}
          <h4>{fullname}</h4>
          <div className='info'>
            <p>
              <RiMoneyDollarCircleFill />{' '}
              <span>
                Balance: <b>{isLoading ? "..." : balance.balance}</b>
              </span>
            </p>
            <p>
              <FaAddressBook />
              <span>
                Post Code: <b>{postCode}</b>
              </span>
            </p>
          </div>
        </div>
      </StyledCard>
    </Link>
  );
};

export default StudentCard;

const StyledCard = styled.div`
  ${tw`w-full border flex flex-col items-center justify-center p-8 rounded shadow text-center hover:shadow-xl transition-transform transform hover:-translate-y-0.5 h-full relative`};

  .body {
    ${tw`w-full`}
  }

  .status {
    ${tw`absolute top-0 right-0 py-1 px-2 bg-pink-900 text-white`}
  }

  h4 {
    ${tw`text-xl capitalize text-gray-600 font-bold mt-5`}
  }
  .info {
    ${tw`mt-2.5 flex flex-col justify-center divide-y divide-gray-100`}
    p {
      ${tw`my-1 flex items-center space-x-1 text-lg text-left`}
      b {
        ${tw`ml-0.5 text-gray-600`}
      }
    }
    svg {
      ${tw`mr-2 text-gray-500 h-4 w-4`}
    }
  }
`;
