import React, { useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import Layout from 'components/common/Layout';
import { CardLoading } from 'components/common/Skeletons';
import tw, { styled } from 'twin.macro';
import ChildTab from './ChildTab';
import TutorTab from 'components/tutor/TutorTab';
import { useMutation, useQuery } from 'react-query';
import { getStudent, updateStatus } from 'api';
import ErrorHandler from 'components/common/ErrorHandler';
import Button from 'components/common/Button';
import Avatar from 'react-avatar';
import EditStudent from './EditStudent';
import Modal from 'components/common/Modal';
import useClickAway from 'hooks/useClickAway';
import { getErrorMessage } from 'utils/getErrors';
import useAlert from 'hooks/useAlert';
import RemoveStudent from '../common/RemoveUser';

const statuses = ['Regular', 'New', 'Looking for tutor', 'Green',
  'Pending 1st lesson', 'Group courses only', 'Call much later',
  'Call later', 'Not active', 'Old new', 'Old green', 'Gave up',
  'Get to restart', 'Bus event', 'Multiple Children'];

const StudentDetails = () => {
  let { studentId } = useParams();
  const [activeTab, setActiveTab] = useState('child');
  const { showError, ShowSuccess } = useAlert();

  // status changer
  const [showStatusChanger, setShowStatusChanger] = useState(false);
  const statusRef = useRef(null);
  useClickAway(statusRef, () => {
    setShowStatusChanger(false);
  });

  const [showEditModal, setEditModal] = useState(false);
  const [showRemoveModal, setRemoveModal] = useState(false);

  const closeModal = () => {
    setEditModal(false);
    setRemoveModal(false);
  };

  const { isLoading, data: student, error, refetch } = useQuery([`student`, studentId], () => getStudent(studentId));

  const { mutate } = useMutation((student) => updateStatus(student), {
    onError: (error) => {
      const message = getErrorMessage(error);
      showError(message);
    },
    onSuccess: () => {
      ShowSuccess('Student Status Changed!');
      refetch();
    },
  });

  const changeStatus = (status) => {
    mutate({ id: student.id, status: status });
  };

  if (error) {
    return <ErrorHandler error={error} />;
  }

  const { id, fullName, postCode, balance, address, status, instrument, phone, message, age, email, userName } = student || {};

  return (
    <Layout>
      {isLoading ? (
        <CardLoading length={12} noCircle />
      ) : (
        <StyledInfo>
          <div className='info'>
            <h2>Student Info</h2>
            <div className='card topCard'>
              <Avatar name={fullName} round={true} size='80' />
              <h2>{fullName}</h2>
              <p tw='my-1'>
                Phone: <a href={`tel:${phone}`}>{phone}</a>
              </p>
              <p>
                Balance: <b>{balance}</b>
              </p>

              <div className='status' ref={statusRef}>
                <p>{status}</p>
                <button onClick={() => setShowStatusChanger((p) => !p)}>Change Status</button>
                {showStatusChanger && (
                  <div className='list'>
                    <span>
                      Change status from <b>{status} </b>to -{' '}
                    </span>
                    <ul>
                      {statuses.map((s) => {
                        return status !== s ? (
                          <li onClick={() => changeStatus(s)} key={s}>
                            {s}
                          </li>
                        ) : null;
                      })}
                    </ul>
                  </div>
                )}
              </div>
            </div>
            <div className='card list'>
              <ul>
                <li>
                  Age: <b>{age}</b>
                </li>
                <li>
                  Email: <b>{email}</b>
                </li>
                <li>
                  UserName: <b>{userName}</b>
                </li>
                <li>
                  Instrument: <b>{instrument}</b>
                </li>
                <li>
                  Address: <b>{address}</b>
                </li>
                <li>
                  PostCode: <b>{postCode}</b>
                </li>
                <li>
                  Message: <span tw='italic text-sm'>{message}</span>
                </li>
              </ul>
            </div>
            <div className='action'>
              <Button onClick={() => setEditModal(true)} text=' Edit Student Info' />
              <Modal heading={`Update ${fullName}`} isOpen={showEditModal} onClose={closeModal}>
                <EditStudent student={{ ...student }} refetch={refetch} closeModal={closeModal} />
              </Modal>
            </div>
            <div className='action delete'>
              <Button onClick={() => setRemoveModal(true)} text='Remove Student' />
              <Modal heading={`Remove ${fullName}`} isOpen={showRemoveModal} onClose={closeModal}>
                <RemoveStudent id={id} name={fullName} closeModal={closeModal} />
              </Modal>
            </div>
          </div>
          <div className='child'>
            <div className='tabs'>
              <button onClick={() => setActiveTab('child')} className={activeTab === 'child' ? 'active' : ''}>
                Child
              </button>
              <button onClick={() => setActiveTab('tutors')} className={activeTab === 'tutors' && 'active'}>
                Tutors
              </button>
            </div>
            {activeTab === 'child' && (
              <ChildTab fetchStudent={refetch} studentId={studentId} child={student?.children} />
            )}
            {activeTab === 'tutors' && (
              <TutorTab
                fetchStudent={refetch}
                studentId={studentId}
                allChild={student?.children}
                tutors={student.assignedTutors}
              />
            )}
          </div>
        </StyledInfo>
      )}
    </Layout>
  );
};

export default StudentDetails;

const StyledInfo = styled.div`
  ${tw`my-10 flex flex-col items-center space-y-5 lg:(flex-row items-start space-x-5 space-y-0) min-h-screen `};

  h2 {
    ${tw`text-2xl font-bold border-b pb-3 mt-4`}
  }

  .info {
    min-width: 300px;
    max-width: 320px;
  }

  .status {
    ${tw`flex flex-col items-center space-y-1.5 mt-5 relative`}
    p {
      ${tw`bg-red-50 border-red-600 text-red-600 px-2 border rounded`}
    }
    button {
      ${tw`focus:outline-none hover:bg-gray-100 px-1 rounded border border-gray-800`}
    }
    .list {
      ${tw`z-10 absolute left-32 -top-10 bg-white shadow-md p-2 rounded w-44 text-center`}
      span {
        ${tw`text-sm`}
      }
      ul {
        ${tw`mt-2`}
        li {
          ${tw`bg-blue-100 w-full px-4 py-1 hover:bg-blue-300 text-blue-600 cursor-pointer whitespace-nowrap font-medium rounded`}
        }
      }
    }
  }

  .card {
    ${tw`mt-5 w-full border flex flex-col items-center justify-center p-8 rounded shadow text-center h-full`}
  }

  .list {
    ul {
      ${tw`flex flex-col text-left space-y-2 divide-y`}
    }
  }

  .action {
    ${tw`mt-5`}
    button {
      ${tw`w-full bg-green-500`}
    }
  }
  .delete {
    button {
      ${tw`w-full bg-red-500`}
    }
  }

  .child {
    ${tw`flex flex-col space-y-2 flex-1`}
    .tabs {
      ${tw`mb-5 flex items-center text-gray-500 text-xl py-2.5 space-x-5 border-b`}
      button {
        ${tw`focus:outline-none rounded px-5 py-1.5 font-medium hover:(bg-indigo-100 text-indigo-800)`}
      }
      .active {
        ${tw`bg-indigo-100 text-indigo-800`}
      }
    }
  }
`;
