import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import Button from 'components/common/Button';
import StyledInput from 'components/common/StyledInput';
import tw, { styled } from 'twin.macro';
import ApiCaller from 'api/axios';
import { useMutation, useQueryClient } from 'react-query';
import { getErrorMessage } from 'utils/getErrors';
import { addStudent } from 'api';
import useAlert from 'hooks/useAlert';
import TextArea from './../common/TextArea';

const AddStudent = ({ closeModal, fetchStudents }) => {
  const { showError, ShowSuccess } = useAlert();
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation((tutor) => addStudent(tutor), {
    onError: (error) => {
      const message = getErrorMessage(error);
      showError(message);
    },
    onSuccess: () => {
      ShowSuccess('Student added!');
      closeModal();
      queryClient.invalidateQueries('allStudents');
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: 'onChange' });

  const onFormSubmit = async (data) => {
    mutate(data);
  };

  return (
    <div>
      <StyledForm onSubmit={handleSubmit(onFormSubmit)}>
        <StyledInput
          register={register}
          validation={{
            required: 'Email is Required!',
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: 'Invalid email address!',
            },
          }}
          error={errors.email}
          name='email'
          label='Student E-mail'
          placeholder='Ex: jhon@mail.com'
        />
        <div tw='flex space-x-4'>
          <StyledInput
            register={register}
            validation={{ required: 'Full Name is Required!' }}
            error={errors.fullname}
            name='fullname'
            label='Full Name'
            placeholder='Ex: John Doe'
          />
          <StyledInput
            register={register}
            validation={{ required: 'Phone is Required!' }}
            error={errors.phone}
            name='phone'
            label='Phone'
            placeholder='Ex: +88015418515'
          />
        </div>
        <div tw='flex space-x-4'>
          <StyledInput
            register={register}
            validation={{ required: 'Age is Required!' }}
            error={errors.age}
            name='age'
            label='Ages'
            placeholder='Ex: 26'
          />
          <StyledInput
            register={register}
            validation={{ required: 'Instrument is Required!' }}
            error={errors.instrument}
            name='instrument'
            label='Instrument'
            placeholder='Ex: Piano'
          />
        </div>
        <div tw='flex space-x-4'>
          <StyledInput
            register={register}
            validation={{ required: 'Address is Required!' }}
            error={errors.address}
            name='address'
            label='Address'
            placeholder='Ex: Dhaka, Bangladesh'
          />
          <StyledInput
            register={register}
            validation={{ required: 'Post Code is Required!' }}
            error={errors.postcode}
            name='postcode'
            label='Post Code'
            placeholder='Ex: 1200'
          />
        </div>

        <TextArea
          register={register}
          error={errors.message}
          name='message'
          label='message'
          placeholder='Ex: Referred by TM'
        />
        <Button loading={isLoading} text='Add Student' type='submit' />
      </StyledForm>
    </div>
  );
};

export default AddStudent;

const StyledForm = styled.form`
  ${tw`flex flex-col space-y-2.5`}
  button {
    ${tw`w-full`}
  }
`;
