export const getErrorMessage = (error) => {
  if (!error.response?.data) return 'Something went wrong';

  switch (error.response.data) {
    case 'snf': {
      return 'Student not found';
    }
    case 'rnf': {
      return 'Tutor not found';
    }
    case 'cnf': {
      return 'Child not found';
    }
    case 'taa': {
      return 'Teacher already assigned';
    }
    case 'sww': {
      return 'Something went wrong';
    }
    case 'sfam': {
      return 'Some Fields are missing';
    }
    case 'cae' : {
      return 'Child already exist with this PostCode'
    }
    default:
      return error.response.data || 'Something went wrong!';
  }
};
