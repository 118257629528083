import axios from "./axios";

/* Dashboard APIS */
export const getDashboardData = async () => {
  const response = await axios.get(
    `/dashboard?date=${new Date().toDateString()}`
  );
  return response.data;
};

/* Student APIs */
export const getAllStudents = async (page) => {
  const { data, headers } = await axios.get(`/student/list?p=${page}&items=12`);

  return {
    students: data,
    pagination: JSON.parse(headers.pagination), 
  };
};

export const getStudent = async (studentId) => {
  const response = await axios.get(`/student/${studentId}`);
  return response.data;
};

export const getStudentBalance = async(studentId) => {
  const response = await axios.get(`/student/balance/${studentId}`)
  return response.data;
}

export const addStudent = async (student) => {
  const response = await axios.post(`/admin/add-new-student`, { ...student });
  return response.data;
};

export const updateStudent = async (student) => {
  const response = await axios.put(`/student/${student.id}`, { ...student });
  return response.data;
};

export const updateStatus = async (student) => {
  const response = await axios.put(`/student/status/${student.id}`, {
    status: student.status,
  });
  return response.data;
};

/* Child */

export const updateChild = async (child) => {
  const response = await axios.put(`/student/child/${child.id}`, { ...child });
  return response.data;
};

export const removeChild = async (id) => {
  const response = await axios.delete(`/student/removechild/${id}`);
  return response.data;
};

/* Tutor APIs */
export const getAllTutors = async (page) => {
  const { data, headers } = await axios.get(`/tutor/list?p=${page}&items=12`);
  return {
    tutors: data,
    pagination: JSON.parse(headers.pagination),
  };
};

export const getTutor = async (tutorId) => {
  const response = await axios.get(`/tutor/${tutorId}`);
  return response.data;
};

export const searchTutor = async (name) => {
  const response = await axios.get(`tutor/search?name=${name}`);
  return response.data;
};

export const addTutor = async (tutor) => {
  const { data } = await axios.post(`/admin/addtutor`, { ...tutor });
  return data;
};

/* Assignment APIs */
export const removeAssignment = async (id) => {
  const response = await axios.delete(`/assign/${id}`);
  return response.data;
};

/* Common APIs */
export const removeUser = async (id) => {
  const response = await axios.delete(`/admin/rmuser?id=${id}`);
  return response.data;
};

/* Add Event */
export const addEvent = async (event) => {
  const response = await axios.post(`/event`, event);
  return response.data;
};

/* Remove Event */
export const deleteEvent = async (id) => {
  const response = await axios.delete(`/event/${id}`);
  return response.data;
};

/* Get events */
export const getAllEvents = async (page) => {
  const { data, headers } = await axios.get(`/event?p=${page}&items=30`);

  return {
    events: data,
    pagination: JSON.parse(headers.pagination),
  };
};
