import React from 'react';
import { FaSpinner } from 'react-icons/fa';
import tw, { styled } from 'twin.macro';

const Button = ({ text, loading, disabled, ...rest }) => {
  return (
    <StyledButton disabled={loading || disabled} {...rest}>
      {loading && <FaSpinner />}
      {text}
    </StyledButton>
  );
};

export default Button;

const StyledButton = styled.button`
  ${tw`bg-blue-500 text-white hover:(bg-blue-700 shadow-md) focus:(outline-none shadow-xl) text-sm font-semibold h-12 px-4 rounded-md space-x-10 text-center disabled:(cursor-not-allowed bg-blue-900 text-gray-200) flex justify-center items-center`};
  svg {
    ${tw`text-white animate-spin inline-block mr-2.5`}
  }
`;
