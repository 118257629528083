import React from 'react';
import { useForm } from 'react-hook-form';
import Button from 'components/common/Button';
import StyledInput from 'components/common/StyledInput';
import tw, { styled } from 'twin.macro';
import TextArea from 'components/common/TextArea';
import useAlert from 'hooks/useAlert';
import { getErrorMessage } from 'utils/getErrors';
import { useMutation, useQueryClient } from 'react-query';
import { addTutor } from 'api';

const AddTutor = ({ closeModal, fetchTutors }) => {
  const { showError, ShowSuccess } = useAlert();
  const queryClient = useQueryClient();
  const { mutate, isLoading } = useMutation((tutor) => addTutor(tutor), {
    onError: (error) => {
      const message = getErrorMessage(error);
      showError(message);
    },
    onSuccess: () => {
      ShowSuccess('Tutor added!');
      closeModal();
      queryClient.invalidateQueries('allTutors');
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: 'onChange' });

  const onFormSubmit = async (data) => {
    mutate(data);
  };

  return (
    <div>
      <StyledForm onSubmit={handleSubmit(onFormSubmit)}>
        <StyledInput
          register={register}
          validation={{
            required: 'Email is Required!',
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: 'Invalid email address!',
            },
          }}
          error={errors.email}
          name='email'
          label='Tutor E-mail'
          placeholder='Ex: jhon@mail.com'
        />
        <StyledInput
          register={register}
          validation={{ required: 'Full Name is Required!' }}
          error={errors.fullname}
          name='fullname'
          label='Full Name'
          placeholder='Ex: John Doe'
        />
        <StyledInput
          register={register}
          validation={{ required: 'Address is Required!' }}
          error={errors.address}
          name='address'
          label='Address'
          placeholder='Ex: Dhaka, Bangladesh'
        />
        <StyledInput
          register={register}
          validation={{ required: 'Account no. is Required!' }}
          error={errors.accno}
          name='accno'
          label='Account no.'
          placeholder='Ex: 2475056001'
        />

        <TextArea
          register={register}
          error={errors.sc}
          name='sc'
          label='SC'
          placeholder='Ex: 12212'
        />

        <Button loading={isLoading} text='Add Tutor' type='submit' />
      </StyledForm>
    </div>
  );
};

export default AddTutor;

const StyledForm = styled.form`
  ${tw`flex flex-col space-y-2.5`}
  button {
    ${tw`w-full`}
  }
`;
