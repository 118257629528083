import React, { useState, useEffect } from 'react';
import tw, { styled } from 'twin.macro';
import { useMutation } from 'react-query';
import { getErrorMessage } from 'utils/getErrors';
import useAlert from 'hooks/useAlert';
import { useHistory } from 'react-router-dom';
import { removeUser } from 'api';

const RemoveUser = ({ isTutor = false, id, name, closeModal }) => {
  const [value, setValue] = useState('');
  const [isConfirmed, setIsConfirmed] = useState(false);
  const { showError, ShowSuccess } = useAlert();
  const history = useHistory();

  const { mutate, isLoading } = useMutation(() => removeUser(id), {
    onError: (error) => {
      const message = getErrorMessage(error);
      showError(message);
    },
    onSuccess: () => {
      ShowSuccess('Removed successfully!');
      history.push(`${isTutor ? '/tutors' : '/students'}`);
      closeModal && closeModal();
    },
  });

  useEffect(() => {
    value === 'YES' ? setIsConfirmed(true) : setIsConfirmed(false);
  }, [value]);

  const handleRemove = () => {
    mutate();
  };

  return (
    <StyledContent>
      <h2>
        Are you sure to remove <b>{name}</b>?
      </h2>
      <p>
        Please type <b>'YES'</b> to confirm.
      </p>
      <input value={value} onChange={(e) => setValue(e.target.value)} placeholder='Type here' type='text' />
      <button disabled={!isConfirmed} onClick={handleRemove}>
        {isLoading ? 'Removing...' : `${isTutor ? 'Remove Tutor' : 'Remove Student'}`}
      </button>
    </StyledContent>
  );
};

export default RemoveUser;

const StyledContent = styled.div`
  ${tw`flex flex-col space-y-5`};

  h2 {
    ${tw`text-red-400 font-medium text-xl`}
    b {
      ${tw`text-red-500`}
    }
  }
  p {
    ${tw`text-lg text-gray-600`}
  }
  input {
    ${tw`p-2 border border-gray-500 rounded w-64`}
  }
  button {
    ${tw`bg-red-400 disabled:bg-gray-300 text-white w-64 p-2 rounded font-medium`}
  }
`;
