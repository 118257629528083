import React from 'react';
import tw, { styled } from 'twin.macro';
import { FaSpinner } from 'react-icons/fa';
import Skeleton from 'react-loading-skeleton';

const FullPageSkeleton = () => {
  return (
    <FullPage>
      <FaSpinner />
    </FullPage>
  );
};

export default FullPageSkeleton;

const FullPage = styled.div`
  ${tw`flex h-screen justify-center items-center`};
  svg {
    ${tw`text-5xl text-blue-500 animate-spin`}
  }
`;

export const CardLoading = ({ length = 8, noCircle = true }) => {
  const dd = Array.from({ length });
  return (
    <Styled>
      {dd.map((d, i) => (
        <div className='item' key={i}>
          {!noCircle && <Skeleton height={80} width={80} count={1} circle={true} />}
          <div className='right'>
            <Skeleton height={10} width='100%' count={5} />
          </div>
        </div>
      ))}
    </Styled>
  );
};

const Styled = styled.div`
  ${tw`my-5 grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5`};
  .item {
    ${tw`flex space-x-3 items-center`}
    .right {
      ${tw`flex-1`}
    }
  }
`;
