import React from 'react';
import tw, { styled } from 'twin.macro';

const TextArea = (props) => {
  const { name, label, register, validation, error, ...rest } = props;

  return (
    <StyledContent error={error}>
      {label && <label htmlFor={label}>{label}</label>}
      <textarea {...register(name, validation)} id={label} {...rest}></textarea>
      <span>{error && error.message}</span>
    </StyledContent>
  );
};

export default TextArea;

const StyledContent = styled.div`
  ${tw`w-full flex flex-col`}
  label {
    ${tw`text-gray-900 uppercase text-xs font-medium`}
  }
  textarea {
    ${tw`mt-1 w-full border-2 border-gray-300 focus:(outline-none border-gray-700) px-4 py-2.5 text-base md:text-lg rounded-md h-32`};
    ${(p) => p.error && tw`border border-red-300`}
  }
  span {
    ${tw`text-red-500 text-xs`}
  }
`;
