import Swal from 'sweetalert2';

const useAlert = () => {
  const showError = (text) => {
    return Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text,
    });
  };
  const ShowSuccess = (text) => {
    return Swal.fire({
      icon: 'success',
      title: 'Done...',
      text,
    });
  };
  const ConfirmDialog = async (text, confirmText, callback) => {
    return Swal.fire({
      title: 'Are you sure?',
      text,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: confirmText || 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        callback();
      }
    });
  };
  return { showError, ShowSuccess, ConfirmDialog };
};

export default useAlert;
