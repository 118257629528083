import React from "react";
import tw, { styled } from "twin.macro";
import moment from "moment";
import { AiFillEdit, AiFillDelete } from "react-icons/ai";
import { useMutation, useQueryClient } from "react-query";
import { deleteEvent } from "api";
import useAlert from "hooks/useAlert";

export default function EventCard({ event }) {
  const { id, title, location } = event;
  const { showError, ShowSuccess } = useAlert();
  const client = useQueryClient();

  const date = new Date(event.date);

  const { mutate } = useMutation((id) => deleteEvent(id), {
    onError: () => {
      showError("Failed to Delete!");
    },
    onSuccess: () => {
      client.invalidateQueries("allEvents");
    },
  });

  return (
    <StyledEventCard>
      <div className="top">
        <div className="date">{moment(date).format("DD")}</div>
        <div className="month">{moment(date).format("MMM")}</div>
      </div>
      <div className="right">
        <div className="title">{title}</div>
        <div className="location">{location}</div>
        <div className="time">{moment(date).format("LT")}</div>
      </div>
      <div className="action">
        <div className="edit">
          <AiFillEdit />
        </div>
        <div
          className="delete"
          onClick={() => {
            mutate(id);
          }}
        >
          <AiFillDelete />
        </div>
      </div>
    </StyledEventCard>
  );
}

const StyledEventCard = styled.div`
  ${tw`m-0 p-2.5 border-2 rounded-md w-full flex flex-row justify-between shadow-md hover:shadow-lg`};
  .top {
    ${tw`p-3 border-2 border-gray-300 rounded-md flex flex-col justify-center items-center hover:bg-gray-200 w-[17%] h-full`}
    .date {
      ${tw`font-bold text-xl`}
    }
    .month {
      ${tw`text-xs uppercase`}
    }
  }

  .right {
    ${tw`w-[77%] ml-2 flex flex-col justify-between pt-1 pb-1`}
    .title {
      ${tw`font-bold text-blue-500 text-lg`}
    }
    .time {
      ${tw`text-sm text-gray-700`}
    }
    .locaton {
      ${tw`text-gray-800 font-bold`}
    }
  }

  .action {
    ${tw`w-[6%] flex flex-col justify-between items-center`}
    .edit {
      ${tw`hover:bg-gray-200 text-gray-600 hover:text-red-900 border-gray-200 hover:border-red-800 border-2 rounded-sm p-1`}
    }
    .delete {
      ${tw`hover:bg-gray-200 text-gray-600 hover:text-red-900 border-gray-200 hover:border-red-800 border-2 rounded-sm p-1`}
    }
  }
`;
