import React from "react";
import { useParams } from "react-router-dom";
import Layout from "components/common/Layout";
import { CardLoading } from "components/common/Skeletons";
import tw, { styled } from "twin.macro";
import Button from "components/common/Button";
import Modal from "components/common/Modal";
import RemoveUser from "components/common/RemoveUser";
import { useQuery } from "react-query";
import { getTutor } from "api";
import ErrorHandler from "components/common/ErrorHandler";

const TutorDetails = () => {
  let { tutorId } = useParams();

  const {
    isLoading,
    data: tutor,
    error,
  } = useQuery([`tutor`, tutorId], () => getTutor(tutorId));

  const [showRemoveModal, setRemoveModal] = React.useState(false);

  const closeModal = () => {
    setRemoveModal(false);
  };

  if (error) {
    return <ErrorHandler error={error} />;
  }

  const { id, fullName, accNo, address, sc, assignedStudents, email } =
    tutor || {};

  return (
    <Layout>
      {isLoading ? (
        <CardLoading length={12} noCircle />
      ) : (
        <StyledInfo>
          <div className="info">
            <div className="left">
              <h2>Tutor Info</h2>
              <div className="card">
                <p>
                  Name: <b>{fullName}</b>
                </p>
                <p>
                  Email: <b>{email}</b>
                </p>
                <p>
                  Account No.: <b>{accNo}</b>
                </p>
                <p>
                  Address:<b> {address}</b>
                </p>
                <p>
                  SC: <b>{sc}</b>
                </p>
              </div>
              <div className="delete">
                <Button
                  onClick={() => setRemoveModal(true)}
                  text="Remove Tutor"
                />
                <p tw="w-48 mt-2 text-sm text-gray-600 text-center">
                  You can't remove tutor who has assigned student(s)
                </p>
                <Modal
                  heading={`Remove ${fullName}`}
                  isOpen={showRemoveModal}
                  onClose={closeModal}
                >
                  <RemoveUser
                    isTutor={true}
                    id={id}
                    name={fullName}
                    closeModal={closeModal}
                  />
                </Modal>
              </div>
            </div>
            <div className="right">
              <h2> Assigned Students</h2>
              {assignedStudents?.map((student) => (
                <ul className="list">
                  <li>
                    Student name: <b>{student.studentName}</b>
                  </li>
                  <li>
                    Child Name:<b> {student.childName}</b>
                  </li>
                  <li>
                    Type: <b>{student.type}</b>
                  </li>
                </ul>
              ))}
            </div>
          </div>
        </StyledInfo>
      )}
    </Layout>
  );
};

export default TutorDetails;

const StyledInfo = styled.div`
  ${tw`my-10 flex flex-col items-center space-y-5 lg:(flex-row items-start space-x-5) min-h-screen p-2.5`};

  .delete {
    ${tw`my-5`}
    button {
      ${tw`w-full bg-red-500`}
    }
  }

  h2 {
    ${tw`text-2xl font-bold border-b pb-3`}
  }
  .info {
    ${tw`flex flex-col space-x-0 space-y-5 md:(flex-row space-y-0 space-x-10)`}
    .card {
      ${tw`mt-4 divide-y divide-gray-300 space-y-2`}
    }
    .right {
      ${tw`border-l-2 pl-5`}
    }
    .list {
      ${tw`border-b p-2.5`}
    }
  }
`;
