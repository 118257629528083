import React from 'react';
import tw, { styled } from 'twin.macro';

const Pagination = ({ currentPage, totalPage, setPage }) => {
  return (
    <StyledPagination>
      <button disabled={currentPage === 1} onClick={() => setPage((old) => Math.max(old - 1, 1))}>
        Prev
      </button>
      <div className='info'>
        <span>{currentPage}</span> of {totalPage} pages
      </div>
      <button disabled={currentPage === totalPage} onClick={() => setPage((old) => old + 1)}>
        Next
      </button>
    </StyledPagination>
  );
};

export default Pagination;

const StyledPagination = styled.div`
  ${tw`flex bg-white rounded-md shadow justify-between items-center max-w-sm mx-auto px-4 py-2`};
  .info {
    ${tw`text-gray-600 px-5`}
    span {
      ${tw`text-gray-800 font-medium`}
    }
  }
  button {
    ${tw`text-gray-800 font-medium focus:outline-none hover:text-blue-500 disabled:text-gray-400`}
  }
`;
