import { useEffect, useState } from 'react';

export const useDebounce = (value, delay = 500) => {
  const [state, setState] = useState(value);

  useEffect(() => {
    const timerId = setTimeout(() => {
      setState(value);
    }, delay);

    return () => {
      clearTimeout(timerId);
    };
  }, [value]);
  return state;
};
