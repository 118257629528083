import React from "react";
import { Link } from "react-router-dom";
import tw, { styled } from "twin.macro";

const StatCard = ({ to, title, subtitle, icon }) => {
  return (
    <Link to={to ? to : "/"}>
      <StyledCard>
        <div className="left">
          <h3>{title}</h3>
          <p>{subtitle}</p>
        </div>
        {icon}
      </StyledCard>
    </Link>
  );
};

export default StatCard;

const StyledCard = styled.div`
  ${tw`border rounded-md shadow-lg hover:shadow-xl p-5 text-gray-700 flex justify-between items-center h-32 xl:h-36`};
  h3 {
    ${tw`text-3xl md:text-4xl font-bold`}
  }
  p {
    ${tw`text-xl md:text-xl uppercase mt-2.5 text-gray-500`}
  }
  svg {
    ${tw`text-4xl md:text-6xl xl:text-5xl xl:mr-5  text-purple-500`}
  }
  &:hover {
    p,
    h3,
    svg {
      ${tw`text-purple-600`}
    }
  }
`;
