import Button from "components/common/Button";
import Layout from "components/common/Layout";
import PageTitle from "components/common/PageTitle";
import React, { useState, useEffect } from "react";
import tw, { styled } from "twin.macro";
import { useQuery, useQueryClient } from "react-query";
import Modal from "components/common/Modal";
import AddEvent from "./AddEvent";
import { getAllEvents } from "api";
import EventCard from "./EventCard";
import Pagination from "components/common/Pagination";

export default function EventPage() {
  const [page, setPage] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const queryClient = useQueryClient();

  const { isLoading, data, error, refetch } = useQuery(
    ["allEvents", page],
    () => getAllEvents(page),
    {
      keepPreviousData: true,
    }
  );

  // useEffect(() => {
  //   if (data?.hasMore) {
  //     queryClient.prefetchQuery(["allEvents", page + 1], () =>
  //       getAllEvents(page + 1)
  //     );
  //   }
  // }, [page]);

  const CloseModal = () => {
    setIsOpen(false);
  };

  if (isLoading) return null;
  else
    return (
      <Layout>
        <EventStyles>
          <div className="head">
            <PageTitle text="All Events" />
            <Button text="Add Event" onClick={() => setIsOpen(true)} />
            <Modal heading="Add An Event" isOpen={isOpen} onClose={CloseModal}>
              <AddEvent CloseModal={CloseModal} fetchEvents={refetch} />
            </Modal>
          </div>
          <EventList>
            {data.events?.map((event) => (
              <EventCard key={event.id} event={event} />
            ))}
          </EventList>
          {/* <Pagination
            currentPage={page}
            totalPage={data.pagination.totalPages}
            setPage={setPage}
          /> */}
        </EventStyles>
      </Layout>
    );
}

const EventStyles = styled.div`
  ${tw`flex flex-col mb-20`};
  .head {
    ${tw`flex justify-between items-center border-b border-gray-300 pb-3`}
    h1 {
      ${tw`border-0 p-0 m-0`}
    }
  }
`;

const EventList = styled.div`
  ${tw`mt-6 grid md:grid-cols-2 lg:grid-cols-3 gap-3 `}
`;
