import React from 'react';
import tw, { styled } from 'twin.macro';

const StyledOptions = (props) => {
  const {
    name,
    label,
    noDefault,
    register,
    validation,
    error,
    items,
    ...rest
  } = props;
  return (
    <StyledContainer>
      {label && <label htmlFor={label}>{label}</label>}
      <select {...register(name, validation)} {...rest} id={label}>
        {!noDefault && (
          <option value="none" disabled>
            {label}
          </option>
        )}
        {items?.map((item) => (
          <option key={item.id} value={item.id}>
            {item.name}
          </option>
        ))}
      </select>
      <span>{error && 'Select a option'}</span>
    </StyledContainer>
  );
};

export default StyledOptions;

const StyledContainer = styled.div`
  ${tw`w-full flex flex-col`};
  label {
    ${tw`text-gray-900 uppercase text-xs font-medium`}
  }
  select {
    ${tw`mt-2.5 w-full border-2 border-gray-300 focus:(outline-none border-gray-700) px-4 py-2.5 text-base md:text-lg rounded-md`};
    ${(p) => p.error && tw`border border-red-300`}
  }
  span {
    ${tw`text-red-500 text-xs`}
  }
`;
