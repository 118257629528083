import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import Login from 'components/auth/Login';
import HomePage from 'components/home';
import StudentPage from 'components/students';
import StudentDetails from 'components/students/StudentDetails';
import TutorDetails from 'components/tutor/TutorDeatails';
import TutorPage from 'components/tutor';
import Event from 'components/events'

export const AuthenticatedRoutes = () => {
  return (
    <Router>
      <Switch>
        <Route exact path='/'>
          <HomePage />
        </Route>
        <Route exact path='/students'>
          <StudentPage />
        </Route>
        <Route exact path='/tutors'>
          <TutorPage />
        </Route>
        <Route exact path='/student/:studentId'>
          <StudentDetails />
        </Route>
        <Route exact path='/tutor/:tutorId'>
          <TutorDetails />
        </Route>
        <Route exact path='/events'>
          <Event/>
        </Route>

        <Redirect from='/login' to='/' />
      </Switch>
    </Router>
  );
};

export const UnAuthenticatedRoutes = () => {
  return (
    <Router>
      <Switch>
        <Route exact path='/login'>
          <Login />
        </Route>
        <Redirect from='*' to='/login' />
      </Switch>
    </Router>
  );
};
