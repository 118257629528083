import React, { useState } from 'react';
import tw, { styled } from 'twin.macro';
import AssignedTutorCard from 'components/cards/AssignedTutorCard';
import Button from 'components/common/Button';
import Modal from 'components/common/Modal';
import AssignTutor from './AssignTutor';

const TutorTab = ({ fetchStudent, studentId, tutors, allChild }) => {
  const [showModal, setShowModal] = useState(false);

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div>
      <Button onClick={() => setShowModal(true)} text='Assign a Tutor' />
      <Modal heading='Assign a Tutor' isOpen={showModal} onClose={closeModal}>
        <AssignTutor closeModal={closeModal} allChild={allChild} studentId={studentId} fetchStudent={fetchStudent} />
      </Modal>
      {(!tutors || !tutors.length === 0) && <div>No Tutor Found</div>}
      <StudentList>
        {tutors?.map((s) => (
          <AssignedTutorCard key={s.id} fetchStudent={fetchStudent} {...s} />
        ))}
      </StudentList>
    </div>
  );
};

export default TutorTab;

const StudentList = styled.div`
  ${tw`grid md:grid-cols-2 2xl:grid-cols-3 gap-5 my-5 mb-20`};
  .student {
    ${tw`shadow-lg flex flex-col p-5  space-y-5 rounded-lg border items-center justify-between`};
    h3 {
      ${tw`text-2xl mb-2 font-semibold text-gray-700`}
    }
    .info {
      ${tw`flex flex-col`};
      p {
        ${tw`text-lg text-gray-500`}
      }
    }
  }
`;
